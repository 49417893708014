/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
/*@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';*/

@import "../node_modules/angular-calendar/css/angular-calendar.css";
/*@import '~mapbox-gl/dist/mapbox-gl.css';
@import '~@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css';*/

@import 'reset.css';

/* for ngx-gallery */
.mat-sidenav-container, .mat-sidenav-content, .mat-tab-body-content {
    transform: none !important;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
    background: #D3D3D3;
    opacity: 0.75 !important;
}

html {
    font-size: 12px
}

.appContent {
    margin: 40px 20px 150px 20px
}


/* Stop Animation on Window Resizing*/
.resize-animation-stopper * {
    animation: none !important;
    transition: none !important;
}


.hide, .hidden {
    display: none !important;
}

.divParent {
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    font-size: 1.6em;
    display: table;
}

.divLine {
    display: table-cell;
    vertical-align: middle;
}

div.form-group {
    padding-top: 20px;
}

div.section, mat-card.section {
    margin-top: 20px;
}

.floatRight {
    float: right;
}

a.mat-button:hover {
    color: white;
    background-color: lightblue;
}

a.up10 {
    margin-top: -10px;
}

.menu-spacer {
    flex: 1 1 auto;
}

h2.pageHeader {
    margin-bottom: 10px;
    border-bottom: 1px solid blue;
    padding-bottom: 5px
}

    h2.pageHeader > a.CTA, h2.pageHeader > i.CTA {
        float: right
    }

    h2.pageHeader > a.CTASecondary, h2.pageHeader > i.CTASecondary {
        float: right;
        font-size: 14px;
    }

    h2.pageHeader > .separator {
        float:right;
        border: none;
        padding: 5px 0;
    }

.carousel {
    height: initial; /* 40vh */
    /*margin-top: 20px*/
}

.mt-4, .my-4 {
    margin-top: 0rem !important;
}

header.timepicker__header {
    height: inherit;
}

@media (min-width: 800px) and (max-width: 850px) {
    .navbar:not(.top-nav-collapse) {
        background: #929FBA !important;
    }
}

/*END from MDB*/

.cdk-overlay-container {
    z-index: 1050 !important;
}

.danger {
    color: red;
}

.warning {
    color: orange;
}

.undo {
    color: forestgreen;
}

.required {
    font-weight: bold;
}

/*Material*/
.mat-form-field-wrapper {
    padding-bottom: 1em;
    margin-top: -10px;
}

mat-form-field.first {
    margin-top: 10px !important;
}

.mat-form-field.x-small {
    width: 80px !important;
}

.mat-form-field.small {
    width: 120px !important;
}

.mat-form-field.medium {
    width: 200px !important;
}

.mat-form-field.normal {
    width: 245px !important;
}

.mat-form-field.large {
    width: 400px !important;
}

.mat-form-field {
    width: 100% !important;
}

mat-form-field.mat-form-field.withMatError {
    /*margin-bottom:15px*/
}

div.form > mat-form-field > div > div.mat-form-field-flex, mat-form-field.first > div > div.mat-form-field-flex {
    padding-top: 10px;
}

div.mat-dialog-content.appDialog {
    padding: 24px;
}

div.mat-dialog-title > h4, div.mat-mdc-dialog-title > h4 {
    display: inline-block;
    width: Calc(100% - 48px);
    background-color: dodgerblue;
    padding: 10px;
    color: white;
    min-height: 46px;
}

    div.mat-dialog-title > h4.nobackground, div.mat-mdc-dialog-title > h4.nobackground {
        display: inline-block;
        width: Calc(100% - 48px);
        background-color: initial !important;
        padding: 10px;
        color: white;
        min-height: 24px;
    }

div.mat-dialog-title > a.close, div.mat-mdc-dialog-title > a.close {
    float: right;
    font-size: 1.5rem;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    color: black;
    border: 1px solid black;
    padding: 10px;
    border-radius: 20px;
    margin-top : 18px
}

    div.mat-dialog-title > a.close:hover, div.mat-mdc-dialog-title > a.close {
        background-color: dodgerblue !important;
        border-color: white !important;
        color: white !important;
    }

mat-dialog-container.mat-dialog-container {
    overflow: hidden !important;
}

.mat-form-field.small > div.mat-input-wrapper {
    width: 120px;
    padding-right: 20px;
}

.mat-form-field.medium > div.mat-input-wrapper {
    width: 200px;
    padding-right: 20px;
}

.mat-form-field.normal > div.mat-input-wrapper {
    width: 245px;
    padding-right: 20px;
}

.mat-form-field.large > div.mat-input-wrapper {
    width: 400px;
    padding-right: 20px;
}

.mat-form-field.auto > div.mat-input-wrapper {
    width: 400px;
    padding-right: 20px;
}

.mat-form-field.inline {
    display: inherit
}

    .mat-form-field.inline > div.mat-input-wrapper {
        display: inline-block
    }

mat-label.placeholder {
    pointer-events: auto;
}

.mat-autocomplete-panel {
    width: 300px;
}

mat-form-field.hidden, mat-step-header.hidden {
    display: none !important;
}

.mat-option {
    width: 300px;
    height: 24px !important;
    font-size: 14px;
}

    .mat-option.auto {
        width: 100%;
        height: initial;
    }

    .mat-option > span.mat-option-text, .mat-checkbox-layout > span.mat-checkbox-label {
        padding: 5px 2px;
    }

.mat-checkbox-layout {
    white-space: normal !important;
}

mat-checkbox.first > label {
    padding-top: 14px
}

mat-checkbox.danger > label > div > div.mat-checkbox-frame {
    background-color: orange
}

mat-checkbox.green > label > div > div.mat-checkbox-frame {
    background-color: green
}

mat-checkbox.blue > label > div > div.mat-checkbox-frame {
    background-color: blue
}

.mat-input-element:disabled {
    color: #777;
}

.mat-expansion-panel-header.table {
    margin: 20px 0;
}

.mat-expansion-panel-header-title.table {
    margin: 10px 0;
}

mat-expansion-panel-header .danger {
    background-color: tomato;
}

mat-panel-title, mat-panel-description {
    padding: 5px;
}

.mat-expansion-panel {
    margin-bottom: 10px !important;
}

    .mat-expansion-panel > .mat-expansion-panel-header.mat-expanded {
        background-color: lightblue
    }

    .mat-expansion-panel > .mat-expansion-panel-header {
        background-color: lightgrey
    }

.mat-expansion-panel-body {
    padding: 0px 16px 16px 24px !important;
}

mat-panel-title.no-padd, mat-panel-description.no-padd {
    padding: 0px;
}

.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group {
    background-color: lightseagreen
}

datatable-scroller {
    width: unset !important
}

app-autocomplete {
    width: Calc(100% - 20px)
}

.app-help {
    display: grid;
    grid-template-columns: minmax(200px, 20%) 1fr;
    column-gap: 20px
}

.horizontalCenter {
    text-align:center;
}

.app-flex-container {
    display: grid;
}
    .app-flex-container.first {
        margin-top: 10px;
    }

    .app-flex-container.app-2col {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
    }

    .app-flex-container.app-2col-edit {
        grid-template-columns: minmax(10%, 80%) minmax(150px, 18%);
        column-gap: 20px;
    }

    .app-flex-container.app-2col-edit_B {
        grid-template-columns: minmax(150px, 18%) minmax(10%, 80%);
        column-gap: 20px;
    }

    .app-flex-container.app-2col-listingMediaDialog {
        grid-template-columns: minmax(350px, 18%) minmax(10%, 80%);
        column-gap: 20px;
    }

    .app-flex-container.app-3col {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 20px
    }

    .app-flex-container.app-3col-icon {
        grid-template-columns: 1fr 1fr 32px;
        column-gap: 20px
    }

    .app-flex-container.app-4col {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 15px
    }

    .app-flex-container, .app-listingSearch-flex-container {
        grid-template-columns: repeat(4, minmax(200px, 1fr));
        column-gap: 20px
    }

        .app-flex-container.header-background {
            background-color: #DEDEDE;
            border-bottom: 2px solid #aaa;
            padding-top: 10px;
            padding-bottom: 10px;
            font-weight: bold;
            top:auto;
            right:auto;
            left:auto;
            position:relative;
        }

@media screen and (max-width: 768px) {
    .app-flex-container {
        display: flex;
        flex-direction: column;
    }
}

/* App - Flex - Stack*/
.app-flex.container {
    display: inline-flex;
    flex-wrap: wrap;
    max-width: 100%;
}

.app-flex.flex-direction {
    flex-direction: row;
}

.app-flex.divFirst {
    width: 165px;
}

.hideScrollBar {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

    .hideScrollBar::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

.app-flex.divFirst.big {
    width: 300px;
}

.app-flex.divNext {
    display: inline-block;
    padding-left: 10px;
    width: calc(100% - 165px)
}

    .app-flex.divNext.withBig {
        display: inline-block;
        width: calc(100% - 300px)
    }

@media screen and (max-width: 500px) {
    .app-flex.flex-direction {
        flex-direction: column;
    }

    .app-flex.divFirst, .app-flex.divFirst.big {
        width: 100%;
    }

    .app-flex.divNext, .app-flex.divNext.withBig {
        width: 100%;
    }
}
/* End - App - Flex - Stack */


mat-card-footer > div.action {
    padding: 5px;
    background-color: antiquewhite;
}

mat-card.row {
    margin-bottom: 10px;
}

    mat-card.row.big {
        margin-bottom: 20px;
    }

mat-card.inline {
    display: inline-block;
    margin: 20px;
}

mat-card.narrow {
    padding: 12px;
}

.mat-card-title {
    margin-bottom:20px;
}

    .mat-card-title > alternate {
        background-color: lightgrey;
        padding: 8px 5px;
        border-radius: 5px
    }

.mat-card-title.small {
    font-size: 16px;
}

mat-card.noborder {
    border: none !important;
    box-shadow: none !important;
}

.mat-tab-body-content {
    padding-top: 20px;
}

mat-sidenav.hideOverflow {
    overflow: hidden;
}

mat-tree-node.mat-tree-node {
    min-height: 24px !important;
}

    mat-tree-node.mat-tree-node > a.selected, mat-list-item.mat-list-item.selected {
        background-color: #39bfcc!important;
        color: white !important;
        padding: 5px 10px;
        height :36px!important
    }

    mat-tree-node.mat-tree-node > a {
        padding: 5px 10px;
    }

        mat-tree-node.mat-tree-node > a.ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

mat-label.placeholder, span.mat-form-field-label-wrapper > label.mat-form-field-label > mat-label {
    font-weight:bold;
    color:black;
}

div.mat-tab-label-content.danger {
    color: red;
}

span.mat-form-field-label-wrapper {
    color: blue !important
}

div.mat-menu-content {
    padding: 10px;
}

button.mat-menu-item, mat-menu-item {
    line-height: 36px;
    height: 36px;
    padding: 0 5px !important;
    background: none !important;
    font-size: 14px;
}

    button.mat-menu-item:hover {
        color: black;
    }

    button.mat-menu-item > i.fa {
        min-width: 24px;
        padding-left: 3px
    }

button .mat-badge {
    margin-right: 20px;
}

    button.mat-badge.inline {
        margin: 20px !important;
    }

span.mdl-radio__ripple-container, span.mdl-radio__ripple-container.mdl-ripple {
    padding: 0px !important;
}

span.actionItem {
    margin: 0px 10px;
}

i {
    margin: 0px 3px;
}

input.missing {
    border: 1px solid tomato;
}

input.disabled {
    border: none;
}
/*End Material*/

.rowGreen {
    background-color: darkseagreen
}

.rowIncomplete {
    background-color: lightcoral
}

div.alt {
    background-color: lightgray
}

div.altListing {
    background-color: whitesmoke;
    border-radius:5px;
}

.noBackground {
    background-color: inherit;
}

/* =DEFAULTS===========================================*/
@font-face {
    src: url('/App_Themes/launch/fonts/mayqueen.ttf');
    font-family: mayqueen;
}

.break {
    clear: both;
    height: 0px;
    overflow: hidden;
}

a {
    cursor: pointer;
    color: rgb(68,138,255);
    text-decoration: none;
    outline: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

    a.actionItem {
        color: blue !important;
        padding: 0px 10px;
    }

mat-tree-node.mat-tree-node > input[type=button].link {
    border: 0;
    background-color: transparent;
    font-size: inherit;
    padding: 5px 10px;
}

    mat-tree-node.mat-tree-node > input[type=button].link.selected {
        background-color: #39bfcc;
        color: white !important;
        padding: 5px 10px;
    }

    mat-tree-node.mat-tree-node > input[type=button].link.ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

a.link {
    color: blue !important;
    cursor: help
}

i.link {
    cursor: help
}

a:hover {
    text-decoration: underline;
    color: #186da1;
}

a ul {
    display: none;
}

a:hover ul {
    display: block;
    position: relative;
    top: 0px;
    transition: opacity 0.5s 0 ease;
    padding: 10px;
    color: #335500;
    font-weight: normal;
    background: #e5e5e5;
    text-align: left;
    border: 1px solid #666;
    z-index: 999999999;
}

/**:not(.btnTip)[title]:hover:after {
  content: attr(title);
  color: blue;
  position: absolute;
  padding:5px 10px;
  margin-top:3px;
  left: 40px;
  top: 40px;
  white-space: nowrap;
  z-index: 20;
  font-size:12px;
  font-weight:200;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background-image: -moz-linear-gradient(top, lightyellow, #cccccc);
  background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0,#eeeeee),color-stop(1, #cccccc));
  background-image: -webkit-linear-gradient(top, lightyellow, #cccccc);
  background-image: -moz-linear-gradient(top, lightyellow, #cccccc);
  background-image: -ms-linear-gradient(top, lightyellow, #cccccc);
  background-image: -o-linear-gradient(top, lightyellow, #cccccc);
}*/

a.DefaultPageLinks {
    color: antiquewhite;
    font-weight: bold;
}

a.searchPropertyAddress {
    font-size: 1.5em
}

span.searchPropertyDescription {
    height: 120px;
    display: block;
    padding: 5px
}

body {
    font-family: Roboto, Arial, sans-serif;
    font-size: 14px;
    line-height: 140%;
    -webkit-font-smoothing: antialiased;
    /*word-wrap: break-word;*/
    margin: 0;
    color: #444444;
}

p,
pre,
/*ul,
ol,
dl,
dd,
blockquote,
address,
table,*/
fieldset,
form {
    margin: 0 0 20px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    line-height: 100%;
}

h1 {
    font-size: 32px;
    margin-bottom: 20px;
    color: #333;
}

h2 {
    font-size: 28px;
    margin-bottom: 25px;
    margin-top: 10px;
    color: #344692;
    position: relative;
}

h1.pageHeader {
    margin: 20px;
    margin-bottom: 0px;
}

h2.pageSummary {
    margin: 50px;
    margin-bottom: 0px;
}

h2 > span, h4 > span {
    border-bottom: 3px solid #CC4E51;
    padding-left: 10px;
    padding-right: 10px;
}

    h4 > span.legal, h4 > div.legal {
        background-color: chocolate;
        color: whitesmoke;
        padding: 5px
    }

    h2:before {
        content: "";
        display: block;
        position: absolute;
        bottom: -5px;
        left: -20px;
        width: 300px;
        height: 2px;
        /*border-bottom: 3px solid #CC4E51;*/
    }

h3 {
    font-size: 22px;
    color: #3A7BC3;
    margin-bottom: 10px;
}

    h3.black {
        color: black !important;
        background-color: inherit !important;
    }

    h3.panel-title {
        text-align: center;
    }

h4 {
    font-size: 18px;
    margin-bottom: 3px;
}

h5 {
    font-weight: bold;
    padding-bottom: 5px;
    line-height: 130%;
}

h6 {
    font-size: 10px;
    margin-bottom: 0;
}

    h1 a,
    h2 a,
    h3 a,
    h4 a,
    h5 a,
    h6 a {
        text-decoration: underline;
        color: rgb(68,138,255);
    }

ul.base {
    margin: 0 !important;
}

    ul.base li {
        margin: 0 !important;
    }

li.message {
    margin-bottom: 15px;
}

td.extend {
    white-space: nowrap;
}

.biggest {
    font-size: 200%;
}

.bigger {
    font-size: 150%;
}

.big {
    font-size: 120%;
}

small {
    font-size: 80%;
}

div.section {
    margin-top: 15px;
}

    div.section.shaded {
        background-color: #eee;
        padding: 10px 5px;
        border: 4px solid white;
    }

div.panel {
    border: 1px solid #000;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding: 5px;
    margin-top: 10px;
}

    div.panel.straight {
        border-radius: 0px !important;
    }

div.mainRow {
    background-color: #eee
}

div.inMiddle {
    padding: 0px;
    border-left: 4px solid white;
    border-right: 4px solid white;
}

div.panel.icon {
    width: auto;
    background-color: lavender;
    margin: 0px;
}

div.panel.auto {
    width: auto;
}

div.panel.initial {
    width: initial;
}

div.panel h1 {
    text-align: left;
    margin-top: -15px;
    height: 20px;
    line-height: 20px;
    font-size: 15px;
    padding-left: 10px;
}

    div.panel h1 span {
        background-color: white;
        color: dodgerblue;
        padding: 0 5px;
    }

div.vertical-leftborder {
    border-left: 1px inset grey;
    padding-left: 10px;
}

div.vertical-rightborder {
    border-right: 1px inset grey;
}

div.small-padding, mat-card.small-padding {
    padding: 5px!important
}

div.medium-padding, mat-card.medium-padding {
    padding: 10px !important
}

div.large-padding, mat-card.large-padding {
    padding: 20px !important
}

div.small-padding-top, mat-card.small-padding-top {
    padding-top: 5px !important
}

div.medium-padding-top, mat-card.medium-padding-top {
    padding-top: 10px !important
}

div.large-padding-top, mat-card.large-padding-top {
    padding-top: 20px !important
}

div.border {
    border: 1px inset #fff;
}

div.borderSolid {
    border: 1px solid #000;
}

div.statusNav {
    margin-top: -50px;
}

div.inline, span.inline {
    display: inline-block;
    margin: 0 10px;
}

i.info {
    cursor: pointer;
    padding: 0 3px 0 3px
}

div.info {
    border: 1px inset #fff;
    font-size: 11px;
    line-height: 14px;
    display: inline-block;
    padding: 3px 10px;
    cursor: pointer;
    color: #000;
    background: #ddd;
    position: relative;
    outline: none;
    vertical-align: middle;
    text-decoration: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

div.siteContent {
    font-size: 140%;
    line-height: 1.4
}

div.info.fancy {
    background-color: #0deaf8;
    font-size: 140%;
}

div.info.pageSummary {
    padding: 10px;
    margin: 10px 0px;
    font-size: 100%;
}

div.rowData.alternate {
    background-color: lightGoldenrodYellow;
    padding: 10px;
    border-image: none;
    width: 100%;
    margin-bottom: 10px;
}

div.rowData {
    background-color: whitesmoke;
    padding: 10px;
    border-image: none;
    width: 100%;
    margin-bottom: 10px;
}


div.info.ToolTip {
    border: none !important;
    background: none !important;
    padding: 10px !important;
    font-size: 80% !important;
}

div.nowrap, label.nowrap {
    white-space: nowrap !important;
}

div.nobackground {
    background: #fff !important;
}

div.noborder, span.noborder, mat-checkbox.noborder > label > span {
    border: none !important;
}

.noborder {
    border: none !important;
}

div.align-center {
    text-align: center;
}

div.firstRowForSteps {
    margin-top: -30px;
}
/* Custom bulleted list
======================================*/
ul.bulleted {
    margin-left: 0;
    padding-left: 0;
}

    ul.bulleted li {
        margin-left: 0px;
        padding-left: 0px;
        padding-left: 10px;
    }

        ul.bulleted li:before {
            content: "� ";
            font-weight: bold;
            color: rgb(68,138,255);
            float: left;
            margin-left: -10px;
        }
/* Unstyled list
=======================================*/
ul.unstyled {
    list-style-type: none;
    margin-left: 0;
}

ul.inline,
ol.inline {
    margin-left: 0;
    list-style-type: none;
}

    ul.inline li,
    ol.inline li {
        display: inline-block;
        padding-left: 15px;
        padding-right: 5px;
    }
/* Numbered list
=======================================*/
ul.numbered {
    list-style: decimal;
    margin-left: 0;
}

    ul.numbered li,
    ol.numbered li {
        display: inline-block;
        padding-left: 15px;
        padding-right: 5px;
    }

ul.upper {
    list-style: upper-alpha;
    margin-left: 0;
}

    ul.upper li,
    ol.upper li {
        display: inline-block;
        padding-left: 15px;
        padding-right: 5px;
    }

ul.basic {
    list-style-type: circle;
}

ul.roman {
    list-style-type: upper-roman;
}

    ul.roman li,
    ol.roman li {
        display: inline-block;
        padding-left: 15px;
        padding-right: 5px;
    }

ol {
    list-style-type: upper-alpha;
    padding-left: 30px;
    padding-top: 10px;
}

    ol ol {
        list-style-type: lower-alpha;
        padding-left: 30px;
        padding-top: 10px;
    }

        ol ol ol {
            list-style-type: decimal;
            padding-left: 30px;
            padding-top: 10px;
        }

        ol ol ul {
            list-style-type: circle;
            padding-left: 30px;
            padding-top: 10px;
        }

    ol ul {
        list-style-type: circle;
        padding-left: 30px;
        padding-top: 10px;
    }

        ol ul ul {
            list-style-type: square;
            padding-left: 30px;
            padding-top: 10px;
        }
/* Definition list
========================================*/
dl dt {
    font-size: 110%;
    font-weight: bold;
}

dl dd {
    margin: 0 0 10px 0;
}

/*img {
    max-width: 100%;
    height: auto;
}
*/
img.icon {
    width: 16px !important;
    height: 16px !important;
    max-width: 16px;
    max-height: 16px;
}

img.thumbnail {
    width: 50px !important;
    height: 50px !important;
    max-width: 50px;
    max-height: 50px;
}
/*Floated images
==========================================*/
img.align-center {
    clear: both;
    margin: 20px auto;
    display: block;
    padding: 2px;
    border: 1px solid #444;
}
/* Default table formatting
============================================*/
table {
    width: 100%;
}

    table.nosizing {
        width: auto !important;
    }

        table.nosizing td {
            padding-right: 10px;
        }

    table td {
        padding: 3px 0px;
        font-size: 14px;
        text-align: left;
    }

    table th {
        padding: 3px 0px;
        font-size: 14px;
        border-width: 2px;
        font-weight: bold;
        font-size: 110%;
        border-bottom: 2px solid #000;
        text-align: center;
    }

    table img {
        vertical-align: middle;
    }

    table.nomargin {
        margin: 0 0 0 0 !important;
    }

    table tr.border.blue {
        border: 2px solid blue;
    }

    table tr.border {
        border: 1px solid #000;
    }

    table tr.topborderonly {
        border-top: 1px solid #000;
    }

    table tr.EOR td {
        border-bottom: 1px solid black !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
    }

    table tr.alternate td {
        background: #ccc;
    }

    table.roundRows tr.alternate td {
        background: #ccc;
    }

    table.roundRows tr td {
        background: #ddd;
        padding: 10px 5px;
    }

    table.roundRows tr {
        border: 1px solid darkgrey;
    }


    table.dynamic.messagebox td:nth-child(1) {
        width: 19.5%
    }

    table.dynamic.messagebox td:nth-child(2) {
        width: 14.5%
    }

    table.dynamic.messagebox td:nth-child(3) {
        width: 14.5%
    }

    table.dynamic.messagebox td:nth-child(4) {
        width: 49.5%
    }

    table.dynamic tr:nth-child(even) {
        background-color: lightgrey;
    }

    table.dynamic th:first-child {
        /*border-left-style : none;*/
    }

    table.dynamic th:last-child {
        /*border-right-style : none;*/
    }

    table.dynamic th {
        border-left-style: solid;
        border-right-style: solid;
        border: 2px solid black;
    }

    table.dynamic td {
        padding: 5px;
    }

    table.roundRows td:first-child {
        border-left-style: solid;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-left: 5px
    }

    table.roundRows td:last-child {
        border-right-style: solid;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        padding-right: 5px
    }

    table tr.border td:first-child {
        border-left-style: solid;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-left: 5px
    }

    table tr.border td:last-child {
        border-right-style: solid;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        padding-right: 5px
    }

p.nomargin {
    margin: 0 0 0 0;
}

p.full, div.full, mat-form-field.full {
    width: 100%;
}
/* Separator line  HR
=======================================*/
.app-hr {
    height: 0;
    background: none;
    border: none;
    border-bottom: 1px dotted #ccc;
    margin: 20px 0;
    clear: both;
    width: 100%;
}

    .app-hr.small {
        margin: 5px 0;
    }

    .app-hr.blank {
        border: none;
    }

.max {
    width: 100%
}
/* =FORM DEFAULTS
===========================================*/
.form-row {
    margin-bottom: 10px;
    clear: both;
    padding-left: 170px;
    *zoom: 1;
}

.form-row-small {
    margin-bottom: 10px;
    clear: both;
    padding-left: 20px;
    *zoom: 1;
}

.form-row:before {
    content: '';
    display: block;
}

.form-row:after {
    content: '';
    display: table;
    clear: both;
}

.form-row.oAuth, .form-row.final {
    border-top: 2px solid #ccc;
    padding-top: 20px;
}

    .form-row.final.no-padd {
        padding-left: 0;
    }

.form-row.no-padd {
    padding-left: 0 !important;
}

.row.no-padd {
    padding-left: 0;
}

label.control-value {
    display: inline-block;
    width: 120px;
    text-align: inherit;
}

    label.control-value.auto {
        width: auto !important;
    }

    label.control-value.normal, a.normal, span.control-value.normal {
        font-weight: normal;
    }

    label.control-value.small {
        width: 70px;
    }

    label.control-value.numeric {
        text-align: right;
    }

label.wide, span.wide {
    width: 300px !important;
}

label.infoLine {
    font-weight: normal;
    padding-top: 10px;
}

    label.infoLine.Value {
        padding: 0px 40px 0px 30px;
    }

span.large {
    font-size: 150%;
}

span.control-value {
    display: inline-block;
    width: 120px;
    text-align: inherit;
}

    span.control-value.numeric {
        text-align: right;
    }

    span.control-value.auto {
        width: auto !important;
    }

    span.control-value.small {
        width: 70px;
    }

label.control-label {
    display: inline-block;
    font-weight: bold;
    float: left;
    padding-top: 4px;
    width: 150px;
    margin-left: -170px;
}

    label.control-label.w600 {
        width: 600px !important;
    }

    label.control-label.subdiv {
        margin-left: -185px !important;
    }

    label.control-label.auto {
        width: auto !important;
    }

    label.control-label.big {
        width: 350px !important;
    }

label.checkboxLabel {
    clear: initial;
    float: none;
    text-align: initial;
    padding-right: 1px;
    margin-left: 1px;
    font-weight: normal;
    display: inline !important;
}

.checkboxLabel label {
    clear: initial;
    float: none;
    text-align: initial;
    padding-right: 1px;
    margin-left: 1px;
    font-weight: normal;
    display: inline !important;
}

label.control-label.inline_big {
    display: inline-block;
    font-weight: bold;
    float: left;
    padding-top: 4px;
    margin-left: -170px;
    width: 250px !important;
}

label.control-label.inline {
    float: none;
    text-align: right;
    padding-right: 10px;
    margin-left: 20px;
}

    label.control-label.inline.min {
        margin-left: 4px !important;
    }

label.control-label.inline_zero {
    clear: initial;
    float: none;
    text-align: initial;
    padding-right: 1px;
    margin-left: 1px;
    font-weight: normal;
    width: auto !important;
}

label.control-label.second {
    width: 200px;
}

span.control-label.second {
    width: 200px;
}

label.base-label {
    display: inline-block;
    font-weight: bold;
    padding: 0 4px 0 0;
    margin-left: 3px;
    width: 170px;
}

    label.base-label.align-right {
        text-align: right;
    }

    label.base-label.auto {
        min-width: 0 !important;
        width: auto !important;
    }

    label.base-label.small {
        text-align: right;
        min-width: 50px !important;
        width: auto !important;
    }

span.base-label.small {
    text-align: right;
    min-width: 50px !important;
    width: auto !important;
}

label.base-label.inline {
    float: none;
    text-align: right;
    padding-right: 10px;
    margin-left: 20px;
    width: 120px;
}

label.base-label.inline_min {
    clear: initial;
    float: none;
    text-align: right;
    padding-right: 2px;
    margin-left: 4px;
}

label.base-label.inline_zero {
    clear: initial;
    float: none;
    text-align: initial;
    padding-right: 1px;
    margin-left: 1px;
    width: auto !important;
}

label.base-label.normal, label.normal, span.normal {
    font-weight: normal;
}

label.link {
    color: rgb(68,138,255);
    text-decoration: underline;
}

label.infoLabelWarning, span.infoLabelWarning {
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px;
    color: black;
    background-color: orange;
    display: inline-block;
}

span.control-label.w600 {
    width: 600px !important;
}

span.control-label.auto {
    width: auto !important;
}

span.control-label.subdiv {
    margin-left: -185px !important;
}

span.control-label.big {
    width: 350px !important;
}

span.base-label {
    display: inline-block;
    font-weight: bold;
    float: left;
    padding: 0 4px 0 0;
    margin-left: 3px;
    width: 170px;
}

    span.base-label.inline {
        float: none;
        text-align: right;
        padding-right: 10px;
        margin-left: 20px;
        width: 120px;
    }

    span.base-label.align-right, td.align-right {
        text-align: right;
    }

    span.base-label.font-normal {
        font-weight: normal;
    }

    span.base-label.auto {
        min-width: 170px;
        width: auto !important;
    }

    span.base-label.normal {
        font-weight: normal;
    }

    span.base-label.inline_big {
        float: none;
        text-align: right;
        padding-right: 10px;
        margin-left: 20px;
        width: 250px;
    }

    span.base-label.inline_min {
        clear: initial;
        float: none;
        text-align: right;
        padding-right: 2px;
        margin-left: 4px;
    }

    span.base-label.inline_zero {
        clear: initial;
        float: none;
        text-align: initial;
        padding-right: 1px;
        margin-left: 1px;
        font-weight: normal;
        width: auto !important;
    }

span.control-label {
    display: inline-block;
    font-weight: bold;
    float: left;
    padding-top: 4px;
    width: 170px;
    margin-left: -170px;
}

    span.control-label.inline {
        float: none;
        text-align: right;
        padding-right: 10px;
        margin-left: 20px;
        width: 120px;
    }

span.control-label-minpadding {
    float: none;
    text-align: right;
    padding-right: 5px;
    margin-left: 5px;
}

span.border {
    border: 1px solid grey;
    background-color: #39bfcc;
}

span.help-help.alt, a.help-help.alt {
    padding: 5px;
    color: white;
    background-color: #39bfcc;
}

span.help-help, a.help-help {
    padding: 5px;
    color: initial;
    background-color: initial;
    line-height: 25px;
    display: inline-block;
    margin: 5px;
    border: 1px solid #39bfcc;
}

span.info {
    border: 1px inset #fff;
    font-size: 11px;
    line-height: 14px;
    display: inline-block;
    padding: 3px 10px;
    cursor: pointer;
    color: #000;
    background: #ddd;
    position: relative;
    white-space: nowrap;
    outline: none;
    vertical-align: middle;
    text-decoration: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

span.infoLabel {
    border: none;
    border-bottom: 1px solid grey !important;
}

span.infoLabelRed {
    border: none;
    border-bottom: 1px solid grey !important;
    color: red;
}

span.float-right {
    float: right;
}

.file-upload {
    margin: 0 10px 0 25px;
    display: inline-block;
}

    .file-upload input[type=file].upload {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        z-index: 10;
        font-size: 20px;
        cursor: pointer;
        height: 36px;
        opacity: 0;
        filter: alpha(opacity=0);
    }

input[type=text].upload {
    border: none;
    font-size: 12px;
    padding-left: 0;
    width: 250px;
}

input[type=number], .mat-form-field-infix > input[type=number] {
    text-align: right
}

div.mat-form-field-infix > input:disabled {
    background-color:inherit!important;
}

    input[type=text].app, input[type=email].app, input[type=number].app,
    input[type=password].app,
    select.app,
    textarea.app {
        display: block;
        margin: 0;
        outline: 0;
        font-size: 12px;
        font-family: Verdana, Geneva, sans-serif;
        padding: 5px;
        display: inline-block;
        vertical-align: middle;
        border: 1px solid #aaa;
        background: #fff;
        width: 300px;
        max-width: 100%;
        margin-right: 10px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-shadow: inset 0 0 3px #cccccc;
        -moz-box-shadow: inset 0 0 3px #cccccc;
        -webkit-box-shadow: inset 0 0 3px #cccccc;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    input[type=text].app.search.auto,
    input[type=password].app.search.auto,
    select.app.search.auto,
    textarea.app.search.auto {
        width: 100% !important;
        max-width: 100% !important;
    }

    input[type=text].app.auto,
    input[type=number].app.auto,
    input[type=password].app.auto,
    select.app.auto,
    textarea.app.auto {
        width: 100% !important;
        max-width: 100% !important;
    }

    input[type=text].app.medium-third,
    input[type=number].app.medium-third,
    input[type=password].app.medium-third,
    select.appmedium-third,
    textarea.app.medium-third {
        width: 195px;
    }

    input[type=text].app.medium,
    input[type=number].app.medium,
    input[type=password].app.medium,
    select.app.medium,
    textarea.app.medium {
        width: 145px;
    }

    input[type=text].app.small,
    input[type=number].app.small,
    input[type=password].app.small,
    select.app.small,
    textarea.app.small {
        width: 93.33333333333333px !important;
    }

    input[type=text].app.x-small,
    input[type=password].app.x-small,
    select.app.x-small,
    textarea.app.x-small {
        width: 67.5px;
    }

    input[type=text].app.xx-small,
    input[type=password].app.xx-small,
    select.app.xx-small,
    textarea.app.xx-small {
        width: 41.666666666666664px;
    }

    input[type=text].app.block,
    input[type=password].app.block,
    select.app.block,
    textarea.app.block {
        width: 100%;
    }

    input[type=number].numeric,
    input[type=text].app.numeric {
        text-align: right !important;
    }

    input[type=text].app:focus,
    input[type=password].app:focus,
    select.app:focus,
    textarea.app:focus {
        border: 1px solid #3497cb;
    }

input.app.flat,
input.app.flat:focus {
    border: none;
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

input[type=checkbox].app,
input[type=radio].app {
    vertical-align: middle;
    position: relative;
    margin: 5px;
    top: -2px;
    *overflow: hidden;
}

input.error,
select.error,
textarea.error {
    border: 1px solid #FF0033;
    color: #de002c;
    background: #fff1f4;
}

    input.error:focus,
    select.error:focus,
    textarea.error:focus {
        border: 1px solid #5897FB;
        background: #fff;
    }

input.app.transparent-input,
select.app.transparent-input,
textarea.app.transparent-input {
    background: transparent;
    border: 1px solid #4b4b4b;
    color: #ccc;
}

progress {
    padding: 2px;
    color: rgb(68,138,255);
}

    progress.small {
        width: 90%;
        border-radius: 3px;
        border: 1px solid lightgrey;
        cursor: help
    }

    progress::-webkit-progress-bar, progress::-webkit-progress-value, progress::-moz-progress-bar {
        background: rgb(68,138,255);
    }

.note {
    font-size: 90%;
    font-weight: normal;
    color: #888;
    position: relative;
    display: block;
    top: 2px;
    line-height: 100%;
}

    .note.spaced {
        line-height: 20px;
    }

.form-row + .note {
    top: -4px;
}

.validationSummary ul {
    list-style-type: circle;
}

    .validationSummary ul li {
        font-size: 12px;
        font-weight: normal;
        color: #c0392b;
        display: block;
    }

span.error {
    font-size: 12px;
    font-weight: normal;
    color: #c0392b;
    display: inline-block;
}

    span.error .block {
        display: block;
    }

select.tall {
    height: 150px
}

select.taller {
    height: 200px;
}

select.tallest {
    height: 250px;
}

textarea {
    resize: vertical;
}

    textarea.small {
        min-height: 100px;
    }

.req:after {
    content: "*";
    padding-left: 2px;
    font-weight: bold;
    color: #c0392b;
}
/* =DEFAULT BUTTONS
===========================================*/
input.btn, a.btn {
    border: none;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    display: inline-block;
    padding: 3px 10px;
    cursor: pointer;
    color: #ffffff;
    background: rgb(68,138,255);
    position: relative;
    white-space: nowrap;
    outline: none;
    vertical-align: middle;
    text-decoration: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

    input.btn:focus, a.btn:focus {
        border: 4px solid lightgrey;
        color: white
    }

    input.btn:hover, a.btn:hover, button:hover {
        background: #317426;
        border-color: #214e19;
        color: #fff;
        text-decoration: none;
    }

    input.btn:active, a.btn:active, button:active {
        margin-bottom: -1px;
        box-shadow: inset 0 1px 1px rgba(255,255,255,0.2), inset 0 -3px 5px rgba(0,0,0,0.3);
        -moz-box-shadow: inset 0 1px 1px rgba(255,255,255,0.2), inset 0 -3px 5px rgba(0,0,0,0.3);
        -webkit-box-shadow: inset 0 1px 1px rgba(255,255,255,0.2), inset 0 -3px 5px rgba(0,0,0,0.3);
    }

    input.btn.action, a.btn.action {
        margin-left: 5px;
        margin-right: 5px;
    }

.btn-danger {
    background: #c0392b;
}

    .btn-danger:hover {
        background: #962d22 !important;
        border-color: #6d2018;
    }

.btn-secondary {
    background: #95a5a6 !important;
    font-weight: normal !important;
}

    .btn-secondary:hover, .btn[disabled], .btn.disabled, .btn:disabled {
        background: #798d8f !important;
        border-color: #617374 !important;
    }

.btn-warning {
    background: #f39c12 !important;
}

    .btn-warning:hover {
        background: #c87f0a !important;
        border-color: #976008 !important;
    }

.btn-success {
    background: #00a924 !important;
}

    .btn-success:hover {
        background: #007619 !important;
        border-color: #00430e !important;
    }

input[type=button].btn-big,
.btn.btn-big,
button.btn-big {
    font-size: 18px;
    line-height: 100%;
    padding: 8px 20px;
}

input[type=button].btn-medium,
.btn.btn-medium,
button.btn-medium {
    font-size: 12px;
    line-height: 100%;
    padding: 8px 20px;
}

input[type=button].btn-small,
.btn.btn-small,
button.btn-small {
    font-size: 12px;
    line-height: 120%;
    padding: 2px 4px;
    font-weight: normal;
}

input[type=button].btn-mini,
.btn.btn-mini,
button.btn-mini {
    font-size: 10px;
    line-height: 100%;
    padding: 2px 4px;
    vertical-align: middle;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.btn.secondRight {
    margin-right: 10px;
}

.btn.second {
    margin-left: 10px;
}

.bold {
    font-weight: bold;
}

.btn.MainAction {
    padding: 20px;
}

li.completed span {
    padding-right: 5px;
    color: #229721;
}

li.actual span {
    padding-right: 5px;
    color: #344692;
}

li.pending span {
    padding-right: 5px;
}

input[type=image].icon16_16 {
    height: 16px !important;
    width: 16px !important;
}

.disabled,
.disabled:active,
.disabled:focus,
*:disabled {
    background: #eee !important;
    background-image: none !important;
    color: #999 !important;
    /*border: none !important;*/
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    cursor: not-allowed !important;
}

div.disabled {
    background-color: transparent !important;
}

input[readonly="readonly"],
inoput:focus[readonly="readonly"] {
    background: #fafafa;
    border: 1px solid #ddd !important;
}

.no-padd {
    padding: 0 !important;
    margin: 0 !important;
}

.strikeThrough {
    text-decoration: line-through;
}

.action-message {
    padding: 15px;
    margin-bottom: 15px;
    border-width: 1px;
    border-style: solid;
    font-weight:bold;
    *zoom: 1;
}

    .action-message:before {
        content: '';
        display: block;
    }

    .action-message:after {
        content: '';
        display: table;
        clear: both;
    }

    .action-message.error {
        background: #fff1f3;
        color: #ff0000;
        border-color: #ff8193;
    }

.span2-with-sep {
    width: 465px;
    margin-bottom: 20px;
    padding-right: 19px;
    float: left;
    margin-right: 20px;
    border-right: 1px solid #f8ebff;
}

    .span2-with-sep.last {
        margin-right: 0;
        padding-right: 0;
        border-right: 0;
    }

.success-color, .green {
    color: #00a924 !important;
    background-color: inherit !important;
}

.warning-color, .warning {
    color: #f39c12 !important;
    background-color: inherit !important;
}

.warning-focused {
    color: black;
    background-color: #f39c12 !important;
    padding: 10px;
    font-weight: bold;
}

.danger-color, .danger {
    color: #c0392b !important;
    background-color: inherit !important;
}

div.warning-border {
    border-left: 3px solid orange;
    padding-left: 10px;
}

.container:before {
    content: '';
    display: block;
}

.default-color {
    color: black;
}

.container:after {
    content: '';
    display: table;
    clear: both;
}

.container.wide {
    width: 100%;
}

body {
    font-family: Roboto, Arial, sans-serif;
    color: #444444;
}

.header {
    padding: 10px 0;
    /*margin-bottom: 1px;*/
    *zoom: 1;
}

    .header:before {
        content: '';
        display: block;
    }

    .header:after {
        content: '';
        display: table;
        clear: both;
    }

a.logo {
    display: inline-block;
    margin: 0px 0px 18px 0px;
    padding-top: 10px;
}

div.logo {
    padding-top: 14px;
}

.banner img {
    display: block;
    float: right;
}

.nomargin {
    margin: 0 !important;
}

.nomargin-bottom {
    margin-bottom: 0 !important;
}

.dropdown-menu > li > a {
    color: white;
    display: inline-block;
}

    .dropdown-menu > li > a.warning-color {
        color: orange
    }


.site-menu {
    background: #344692;
    /*height: 40px;*/
}

    .site-menu ul {
        margin-bottom: 0;
    }

@media all and (min-width:768px) {
    .site-menu ul a {
        color: darkblue;
    }

        .site-menu ul a.btn {
            color: #000;
            font-weight: normal;
            margin-top: 3px;
            padding-bottom: 7px;
            padding-top: 7px;
            /*margin-left: 20px;*/
        }

            .site-menu ul a.btn.btn-link {
                background: none;
                color: #4390BE;
            }
}

.main-menu li {
    float: left;
    font-weight: bold;
}

    .main-menu li a {
        height: 40px;
        line-height: 40px;
        display: block;
        padding: 0 10px;
    }

        .main-menu li a.active {
            background: #4390BE;
        }

.submenu {
    background: #4390BE;
    margin-bottom: 20px;
}

    .submenu ul {
        margin-left: 15px;
    }

        .submenu ul li {
            float: left;
            margin-right: 30px;
            line-height: 30px;
            color: #fff;
        }

        .submenu ul a {
            display: block;
            height: 30px;
            line-height: 30px;
            color: #fff;
        }

.secondary-menu {
    float: right;
    color: #fff;
    text-align: right;
}

    .secondary-menu li {
        display: inline-block;
        margin-left: 15px;
    }

        .secondary-menu li a {
            line-height: 40px;
            display: block;
            padding: 0 10px;
        }

            .secondary-menu li a.btn {
                line-height: 140%;
                margin-top: -3px;
                font-weight: normal;
            }

            .secondary-menu li a.active {
                background: #4390BE;
            }

.menuFirstItem {
    padding-left: 10px;
}

.menuLastItem {
    padding-right: 10px;
}


.fullwidthbanner-container {
    width: 100%;
    max-height: 500px !important;
}

.tp-bannertimer {
    display: none;
}

.service-thumb {
    padding-top: 1px;
    border-top-width: 3px;
    border-top-style: solid;
    margin-bottom: 10px;
}

.provider .service-thumb {
    border-color: #419b32;
}

.seller .service-thumb {
    border-color: #cb4d50;
}

.buyer .service-thumb {
    border-color: rgb(68,138,255);
}

.ads {
    padding-top: 20px;
    clear: both;
}

.box-section {
    border: 1px solid #a8a8a8;
    margin-bottom: 20px;
    background-image: -webkit-linear-gradient(top, #f4f4f4 0%, #fffefe 49.61%, #fffefe 100%, #ffffff, #000000);
    background-image: -moz-linear-gradient(top, #f4f4f4 0%, #fffefe 49.61%, #fffefe 100%, #ffffff, #000000);
    background-image: -o-linear-gradient(to top, #f4f4f4 0%, #fffefe 49.61%, #fffefe 100%, #ffffff, #000000);
    background-image: -ms-linear-gradient(to top, #f4f4f4 0%, #fffefe 49.61%, #fffefe 100%, #ffffff, #000000);
    background-image: linear-gradient(to top, #f4f4f4 0%, #fffefe 49.61%, #fffefe 100%, #ffffff, #000000);
    *zoom: 1;
}

    .box-section:before {
        content: '';
        display: block;
    }

    .box-section:after {
        content: '';
        display: table;
        clear: both;
    }

    .box-section:first-child {
        margin-top: 20px;
    }

    .box-section h3 {
        background: #818181;
        color: #fff;
        padding: 5px 10px;
        margin-bottom: 20px;
        clear: both;
    }

        .box-section h3 .btn {
            margin-top: -1px;
            margin-right: -3px;
        }

    .box-section h4 {
        border-bottom: 3px solid #344692;
        margin-bottom: 10px;
        padding-bottom: 5px;
    }

        .box-section h4 .btn {
            margin-top: -5px;
        }

    .box-section ul.listing li {
        padding: 20px 0;
        border-bottom: 1px solid #ccc;
    }

        .box-section ul.listing li:first-child {
            padding-top: 0;
        }

        .box-section ul.listing li:last-child {
            border-bottom: 0;
        }

    .box-section ul.normal li {
        padding: 0;
        border-bottom: 0;
    }

        .box-section ul.normal li:first-child {
            padding-top: 0;
        }

        .box-section ul.normal li:last-child {
            border-bottom: 0;
        }

.price {
    float: right;
    font-weight: bold;
    color: #cb4d50;
}

h3.legend {
    color: #4390BE;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.listing-table th {
    background: #dedede;
    padding: 3px 5px;
    border: 1px solid #aaa;
    border-bottom-width: 2px;
    text-align: center;
}

    .listing-table th.small, table th.small {
        width: 4%;
        max-width: 4%;
    }

    .listing-table th.x-small, table th.x-small {
        width: 3%;
        max-width: 3%;
    }

    .listing-table th.xx-small, table th.xx-small {
        width: 2%;
        max-width: 2%;
    }

    .listing-table th.large, table th.large {
        width: 20%;
        max-width: 20%;
    }

    .listing-table th.x-large, table th.x-large {
        width: 25%;
        max-width: 25%;
    }

    .listing-table th.xx-large, table optgroup.xx-large {
        width: 30%;
        max-width: 30%;
    }

    .listing-table th.medium, table td.medium {
        width: 10%;
        max-width: 10%;
    }

    .listing-table th.normal, table.th.normal {
        width: 15%;
        max-width: 15%;
    }

.listing-table td {
    padding: 5px 2px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: none;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    vertical-align: top;
}

    .listing-table td.valignmiddle {
        vertical-align: middle !important;
    }

    .listing-table td.border {
        border: 1px solid #ccc !important;
    }

    .listing-table td.small, th.small {
        width: 60px;
        max-width: 60px;
    }

    .listing-table td.x-small, th.x-small {
        width: 40px;
        max-width: 40px;
    }

    .listing-table td.xx-small, th.xx-small {
        width: 20px;
        max-width: 20px;
    }

    .listing-table td.big, th.big {
        width: 250px;
        max-width: 250px;
    }

    .listing-table td.bigger, th.bigger {
        width: 350px;
        max-width: 350px;
    }

    .listing-table td.verbig, .table > thead > tr > th.verybig {
        width: 600px;
        max-width: 600px;
    }

    .listing-table td.medium, th.medium {
        width: 150px;
        max-width: 150px;
    }

    .listing-table td.normal, th.normal {
        width: 200px;
        max-width: 200px;
    }

    .listing-table td.align-center, .listing-table th.align-center {
        text-align: center;
    }

    .listing-table td.align-right, .listing-table th.align-right {
        text-align: right;
    }

.listing-table tr.alternate td, div.alternate {
    background: #ccc;
}

.listing-table tr.bottomborderonly td {
    border-bottom: 1px solid #ccc !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

.listing-table tr:hover td {
    background: #e5f4fc;
    font-size: larger;
}

.listing-table tr.border td,
.listing-table td.border {
    border: 1px solid #ccc !important;
}

.fixed-table-container {
    height: 150px;
    border: 1px solid #ccc;
    margin: 10px auto;
    background-color: white;
    position: relative;
    padding-top: 35px;
    /* height of header */
}

    .fixed-table-container.two {
        padding-top: 70px;
    }

    .fixed-table-container.small {
        height: 75px;
    }

    .fixed-table-container.big {
        height: 300px;
    }

    .fixed-table-container.bigger {
        height: 600px;
    }

    .fixed-table-container.auto {
        height: auto !important;
    }

    .fixed-table-container table {
        background-color: white;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        margin-bottom: 0;
    }

        .fixed-table-container table th {
            background: none;
            border: none;
            padding: 0 5px;
        }

    .fixed-table-container td.noborder {
        border: none !important;
    }

    .fixed-table-container tr.alternate td {
        background: #ccc;
    }

    .fixed-table-container .th-inner {
        position: absolute;
        top: 0;
        line-height: 35px;
        /* height of header */
        text-align: left;
        border-left: 1px solid #aaa;
        padding-left: 5px;
        margin-left: -6px;
        font-size: 14px;
    }

        .fixed-table-container .th-inner.second {
            top: 35px
        }

.fixed-table-container-inner {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
}

    .fixed-table-container-inner td {
        vertical-align: top;
    }
/*.fixed-table-container-inner tr td {
    line-height:100%
}
.fixed-table-container-inner tr {
    line-height:200%
}
.fixed-table-container-inner tr.normal {
    line-height:100%
}*/

.fixed-table-container.narrow {
    width: 80%;
    margin: 0px;
}

.fixed-table-container.narrower {
    width: 65%;
    margin: 0px;
}

.fixed-table-container.narrowest {
    width: 50%;
    margin: 0px;
}

.header-background {
    background-color: #DEDEDE;
    border-bottom: 2px solid #aaa;
    height: 35px;
    /* height of header */
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
}

    .header-background.two {
        height: 70px
    }

.tooltipster-default {
    box-shadow: 0px 1px 2px #eeeeee;
    -moz-box-shadow: 0px 1px 2px #eeeeee;
    -webkit-box-shadow: 0px 1px 2px #eeeeee;
}

.input-date {
    display: inline-block;
    position: relative;
    margin-right: 10px;
}

    .input-date:after {
        content: "\f073";
        font-family: Fontawesome;
        margin-left: -30px;
    }

.step-title {
    color: #CC4E51;
}

.progress-steps {
    padding-left: 10px;
    border-bottom: 2px dotted #ccc;
    margin-bottom: 20px;
    text-align: center;
}

    .progress-steps ul {
        border-top: 3px solid #ccc;
        display: inline-block;
        padding-top: 0px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .progress-steps li {
        display: inline-block;
        position: relative;
        width: 150px;
    }

        .progress-steps li.medium {
            width: 70px;
        }

        .progress-steps li.small {
            width: 35px;
        }

    .progress-steps a {
        display: block;
        text-transform: uppercase;
        font-size: 80%;
        padding-top: 20px;
        font-weight: bold;
        color: #333;
    }

        .progress-steps a span.actual {
            width: 36px;
            background-color: rgb(68,138,255);
        }

        .progress-steps a span {
            display: block;
            position: absolute;
            background: #ccc;
            color: #fff;
            width: 24px;
            height: 24px;
            left: 50%;
            margin-left: -12px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            line-height: 24px;
            margin-top: -34px;
            z-index: 1;
            font-size: 150%;
            font-weight: normal;
            color: black;
        }

    .progress-steps li:first-child a:after {
        content: "";
        display: block;
        width: 50%;
        height: 3px;
        background: #fff;
        position: absolute;
        top: -3px;
        left: 0;
    }

    .progress-steps li:last-child a:after {
        content: "";
        display: block;
        width: 50%;
        height: 3px;
        background: #fff;
        position: absolute;
        top: -3px;
        right: 0;
    }

    .progress-steps li.completed span {
        background: #4390BE;
    }

    .progress-steps li.actual span {
        background: #344692;
    }

    .progress-steps li.completed:after {
        content: "";
        display: block;
        position: absolute;
        background: #4390BE;
        color: #fff;
        width: 150px;
        top: -3px;
        height: 3px;
        left: 50%;
    }

.tabs-menu {
    clear: both;
    overflow: hidden;
    margin-bottom: 0;
    border-bottom: 2px solid #4390BE;
}

    .tabs-menu li {
        float: left;
        margin-bottom: 0;
        margin-right: 2px;
    }

        .tabs-menu li a {
            display: block;
            padding: 4px 10px 8px 10px;
            font-weight: inherit;
            background: #aaa;
            color: #fff;
            position: relative;
            top: 4px;
            -webkit-border-radius: 3px 3px 0 0;
            -moz-border-radius: 3px 3px 0 0;
            border-radius: 3px 3px 0 0;
        }

            .tabs-menu li a:hover {
                background: #666;
                text-decoration: none;
            }

        .tabs-menu li.active a {
            background: #4390BE;
            padding: 6px 10px 8px 10px;
            color: #fff;
            text-decoration: none;
            font-weight: bold;
            top: 0;
        }

.tab-container {
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
}

.tab-pane {
    padding-top: 15px;
}

.scroll-container {
    padding: 20px;
    border: 20px solid #fff;
    overflow-y: scroll;
    max-height: 400px;
    margin-bottom: 30px;
    box-shadow: 0 0 10px #ddd, 0 0 1px 1px #ccc;
    -moz-box-shadow: 0 0 10px #ddd, 0 0 1px 1px #ccc;
    -webkit-box-shadow: 0 0 10px #ddd, 0 0 1px 1px #ccc;
}

.big {
    font-size: 120%;
}

.footer {
    margin-top: 20px;
    padding-top: 20px;
}

    .footer ul li {
        display: inline-block;
        margin: 0 20px;
    }

    .footer .copy {
        font-size: 90%;
    }

footer.page-footer {
    background-color: #414a5c;
    margin-top: 2rem;
}

/* =OVERRIDE EXCEPTIONS
===========================================*/
.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.inline-flex {
    display: inline-flex;
}

.clear-both {
    clear: both;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.float-left {
    float: left;
}

.relative {
    position: relative;
}

.disabled {
    cursor: not-allowed;
}

.last {
    margin-right: 0 !important;
}

.popuprow:before {
    content: '';
    display: block;
}

.popuprow:after {
    content: '';
    display: table;
    clear: both;
}

.popuprow {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 4px;
    margin-bottom: 4px;
    line-height: 140%;
}

    .popuprow.final {
        padding-top: 15px !important;
    }

    .popuprow.info {
        line-height: 100% !important;
    }

.tablerow {
    margin-left: 2px;
    margin-right: 2px;
    padding: 3px;
}

    .tablerow.forAjaxTab {
        margin-left: 15px !important;
    }


/*TreeView*/
.TreeView table {
    margin: 0 0 0 0;
    padding: 0px;
    height: 22px;
    overflow: hidden;
}

    .TreeView table td {
        float: left;
        max-width: 100px;
        padding: 0px;
    }

    .TreeView table tbody th {
        float: left;
    }

    .TreeView table tr {
        padding: 0px;
        background-color: white;
    }

.processMessage {
    position: fixed;
    top: 45%;
    left: 45%;
    padding: 20px;
    width: 30%;
    z-index: 1001;
    background-color: #ccc !important;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 18px;
    /*border: solid 2px #666666;
    border-radius:10px;
    -moz-border-radius:10px;
    -webkit-border-radius:10px;*/
}



.progressBackgroundFilter {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    overflow: hidden;
    padding: 0;
    margin: 0;
    /*background-color: Gray;*/
    background-color: #ccc;
    filter: alpha(opacity=50);
    opacity: 0.5;
    z-index: 1000;
}

/* Override for JQuery Dialog Close button alignment */
.ui-icon {
    left: 0px !important;
    top: 0px !important;
}

/*Password Strength*/
.PasswordStength_Weak {
    color: Red;
    background-color: Red;
}

.PasswordStength_Average {
    color: Blue;
    background-color: Blue;
}

.PasswordStength_Good {
    color: Green;
    background-color: Green;
}

.HelpTextIndicator {
    width: 16px;
    height: 14px;
    background-image: url(/assets/images/question_white.png);
    overflow: hidden;
    cursor: help;
}

.searchResultHeader {
    margin-top: 20px;
    padding: 10px 0;
    font-size: 18px;
    margin-bottom: 5px;
}

.searchResultText {
    max-height: 100px;
    overflow: hidden;
    background-color: #dee2e6;
    padding: 5px;
    padding-left: 10px;
}

/* AutoComplete  */
.autocomplete_completionListElement {
    margin: 2px !important;
    background-color: white;
    color: windowtext;
    border: buttonshadow;
    border-width: 0px;
    border-style: solid;
    cursor: default;
    overflow: auto;
    height: 200px;
    text-align: left;
    list-style-type: none;
}

.autocomplete_highlightedListItem {
    background-color: #ffff99;
    color: black;
    padding: 1px 2px 1px 2px;
}

.autocomplete_listItem {
    background-color: window;
    color: windowtext;
    padding: 1px 2px 1px 2px;
}

/* Hexadecimal entities for the icons */

.add:before {
    content: "\271A";
    padding: 0 5px;
}

.edit:before {
    content: "\270E";
    padding: 0 5px;
}

.delete:before {
    content: "\2718";
    padding: 0 5px;
}

.save:before {
    content: "\2714";
    padding: 0 5px;
}

.email:before {
    content: "\2709";
    padding: 0 5px;
}

.like:before {
    content: "\2764";
    padding: 0 5px;
}

.next:before {
    content: "\279C";
    padding: 0 5px;
}

.star:before {
    content: "\2605";
    padding: 0 5px;
}

.spark:before {
    content: "\2737";
    padding: 0 5px;
}

.play:before {
    content: "\25B6";
    padding: 0 5px;
}

rating.small > span > span > i.start-icon {
    font-size: 1em !important;
}

rating.smaller > span > span > i.start-icon {
    font-size: .8em !important;
}

span.rating.disabled {
    background: none !important;
}


/* Gallerific */
div.content {
    /* The display of content is enabled using jQuery so that the slideshow content won't display unless javascript is enabled. */
    display: none;
    float: right;
    width: 550px;
}

    div.content a, div.navigation a {
        text-decoration: none;
        color: #777;
    }

        div.content a:focus, div.content a:hover, div.content a:active {
            text-decoration: underline;
        }

div.controls {
    margin-top: 5px;
    height: 23px;
}

    div.controls a {
        padding: 5px;
    }

div.ss-controls {
    float: left;
}

div.nav-controls {
    float: right;
}

div.slideshow-container {
    position: relative;
    clear: both;
    height: 502px; /* This should be set to be at least the height of the largest image in the slideshow */
}

div.loader {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(/assets/images/loader.gif);
    background-repeat: no-repeat;
    background-position: center;
    width: 550px;
    height: 502px; /* This should be set to be at least the height of the largest image in the slideshow */
}

div.slideshow {
}

    div.slideshow span.image-wrapper {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }

    div.slideshow a.advance-link {
        display: block;
        width: 550px;
        height: 502px; /* This should be set to be at least the height of the largest image in the slideshow */
        line-height: 502px; /* This should be set to be at least the height of the largest image in the slideshow */
        text-align: center;
    }

        div.slideshow a.advance-link:hover, div.slideshow a.advance-link:active, div.slideshow a.advance-link:visited {
            text-decoration: none;
        }

    div.slideshow img {
        vertical-align: middle;
        border: 1px solid #ccc;
    }

div.download {
    float: right;
}

div.caption-container {
    position: relative;
    clear: left;
    height: 75px;
}

span.image-caption {
    display: block;
    position: absolute;
    width: 550px;
    top: 0;
    left: 0;
}

div.caption {
    padding: 12px;
}

label.caption {
    font-weight: bold;
    display: inline-flex;
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 32px;
}

    label.caption.large {
        width: 200px;
    }

    label.caption.auto {
        width: initial;
    }

    label.caption.small {
        width: 80px;
    }

label.data-value {
    display: inline-flex;
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 32px;
}

    label.data-value.large {
        width: 200px;
    }

    label.data-value.small {
        width: 80px;
    }

    label.data-value.auto {
        width: initial;
    }

div.image-title {
    font-weight: bold;
    font-size: 1.4em;
}

div.image-desc {
    line-height: 1.3em;
    padding-top: 12px;
}

div.navigation {
    /* The navigation style is set using jQuery so that the javascript specific styles won't be applied unless javascript is enabled. */
}

div.tab-content {
    border: 1px solid lightgrey;
    padding: 5px;
    /*border-top:none;*/
}

ul.normal > li {
    padding-bottom: 10px;
}

ul.thumbs {
    clear: both;
    margin: 0;
    padding: 0;
}

    ul.thumbs li {
        float: left;
        padding: 0;
        margin: 5px 10px 5px 0;
        list-style: none;
    }

a.thumb {
    padding: 2px;
    display: block;
    border: 1px solid #ccc;
}

a.large {
    font-size: 250%;
    padding: 0px 5px;
}

    a.large:hover {
        font-size: 300%;
    }

a.medium {
    font-size: 150%;
    padding: 0px 5px;
}

    a.medium:hover {
        font-size: 200%
    }

a.full {
    width: 100%;
    text-align: center
}

a.green {
    color: green;
}

a.warning {
    color: orange;
}

a.danger {
    color: red;
}

ul.thumbs li.selected a.thumb {
    background: #000;
}

a.thumb:focus {
    outline: none;
}

ul.thumbs img {
    border: none;
    display: block;
}

div.pagination {
    clear: both;
}

div.navigation div.top {
    margin-bottom: 20px;
    height: 11px;
}

div.navigation div.bottom {
    margin-top: 12px;
    width: 100%
}

div.pagination a, div.pagination span.current, div.pagination span.ellipsis {
    display: block;
    float: left;
    margin-right: 2px;
    padding: 4px 7px 2px 7px;
    border: 1px solid #ccc;
}

    div.pagination a:hover {
        background-color: #eee;
        text-decoration: none;
    }

div.pagination span.current {
    font-weight: bold;
    background-color: #000;
    border-color: #000;
    color: #fff;
}

div.pagination span.ellipsis {
    border: none;
    padding: 5px 0 3px 2px;
}

div.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Bottom Help Panel */
.panel-content {
    width: 100%;
    background-color: white;
    z-index: 99999;
    position: relative;
    padding: 0 80px;
    border-top: 1px solid black;
    max-height: 200px;
    overflow-y: auto;
    margin: 0 -20px
}

    .panel-content .content {
        overflow: hidden;
        margin: 0 auto;
        max-width: 900px;
        width: 98%;
    }

.red-background, .danger-background {
    background-color: red;
    color: white;
}

.grey-background{
    background-color: lightgrey;
    color: black;
}

div.message.red-background, div.message.danger-background {
    padding: 5px;
    color: white
}

.warning-background {
    background-color: orange!important;
}

div.message.warning-background {
    padding: 10px;
    color: white!important
}

.green-background {
    background-color: #85bf78 !important;
    color:white !important;
}

.clearfix:before, .clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}
/* End - Bottom Help Panel*/

.close.icon {
    background: url(/assets/images/close.png);
}

.hide-for-xsmall, .hide-for-mobile, .hide, .hide-for-mobile {
    display: none;
}

.form-group.first {
    margin-top: 15px
}
/* MOBILE CONTENT CUSTOM*/
@media all and (max-width:767px) {
    .mobileTest {
    }

    .row {
        margin-right: 0px;
        margin-left: 0px;
    }

    h2.pageSummary {
        margin: 5px;
    }

    h2 > a.x-small {
        font-size: x-small;
    }

    div.pageHeader {
        padding-top: 0px
    }

    .pageHeader2 {
        float: right;
        top: -50px;
    }

    div.logo {
        margin: 0px;
        z-index: 100;
        width: 150px;
        padding-top: 12px;
    }

    a.logo {
        margin: 6px;
    }

    .container.header {
        width: 100%;
        padding: 5px;
        border-bottom: 1px solid blue;
        max-height: 130px;
    }

    .site-menu .container {
        max-width: 100px !important;
        float: right !important;
    }

    .findHome {
        padding: 5px 0px;
    }

    .main-search {
        position: relative;
        margin-top: 4px;
        margin-bottom: 30px;
    }

    div.info.pageSummary {
        padding: 5px;
        margin: 5px 0px;
        font-size: 140%;
    }

    .main-search:before {
        display: block;
        content: "";
        width: 100%;
        height: 2px;
        background: #cb4d50;
        margin-top: -2px;
        margin-bottom: 2px;
    }

    .main-search .form {
        padding: 10px 20px;
        position: absolute;
        left: 20%;
        margin-left: -30px;
        top: 40px;
        background: #333;
        background: rgba(0, 0, 0, 0.7);
        width: 60%;
        z-index: 100;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        *zoom: 1;
    }

    .main-search .formSUB {
        padding: 10px 20px;
        position: absolute;
        left: 20%;
        margin-left: -300px;
        top: 100px;
        background: #ccc;
        z-index: 100;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        *zoom: 1;
    }

    .main-search .form:before {
        content: '';
        display: block;
    }

    .main-search .form:after {
        content: '';
        display: table;
        clear: both;
    }

    .main-search h2 {
        font-size: 18px;
        font-weight: 150;
        color: #fff;
        text-align: center;
        margin-bottom: 20px;
    }

        .main-search h2:before {
            content: none;
            display: none !important;
            border: 0;
        }

    .main-search select {
        background: rgb(68,138,255);
        color: #fff;
        border: none;
        float: left;
        height: 36px;
        font-size: 18px;
        margin-right: 0;
        width: auto !important;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
    }

    .main-search input {
        width: 80%;
        color: #ccc;
        border-color: #fff;
        height: 36px;
        float: left;
        font-size: 18px;
        margin-right: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
    }

        .main-search input:focus {
            color: #444;
        }

    .main-search button {
        display: block;
        border: none;
        float: left;
        height: 36px;
        line-height: 32px;
        font-size: 18px;
        margin: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
    }

    div.map, .main-search.home, .mobileHide {
        display: none;
    }

    .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
        float: none;
        width: 100%;
    }

    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
        padding-right: 0px;
        padding-left: 0px;
    }

    .container {
        padding-left: 5px;
        padding-right: 5px;
        min-width: 100% !important;
    }

    .service-thumb {
        border-top-width: 0px;
    }

        .service-thumb.home {
            width: 100%;
        }

    div.vertical-leftborder.second {
        border-top: 1px inset #fff;
        border-left: none;
        margin-top: 10px;
        padding-top: 15px;
    }

    div.vertical-rightborder {
        border-right: none;
        padding-bottom: 15px;
    }

    label.base-label.inline {
        /* margin-left: 2px; */
        display: block;
        padding-left: 0px;
        margin-left: -93px;
        width: 170px;
        /* float: left; */
        padding-top: 15px;
        /* width: 150px; */
    }

    table {
        overflow-x: auto;
        display: block;
    }

        /* CSS Responsive table */
        table.responsive,
        table.responsive thead,
        table.responsive tbody,
        table.responsive th,
        table.responsive td,
        table.responsive tr {
            display: block;
            overflow-y: hidden;
        }
            /*table.responsive thead tr { position: absolute; top: -9999px; left: -9999px; }*/
            table.responsive tr {
                margin-bottom: 10px;
            }

            table.responsive td {
                border: none;
                position: relative;
            }

                table.responsive td:before {
                    position: absolute;
                    top: 6px;
                    left: 6px;
                    width: 40%;
                    padding-right: 10px;
                    text-align: left;
                    word-wrap: break-word;
                }
                /*table.responsive tr:first-child { position: absolute; top: -9999px; left: -9999px; }*/
                table.responsive td:before {
                    content: attr(data-content);
                    height: 50px;
                }

                table.responsive td.small-padding {
                    padding-top: 5px;
                    padding-bottom: 5px;
                }

                table.responsive td.medium-padding {
                    padding-top: 15px;
                    padding-bottom: 15px;
                }

                table.responsive td.large-padding {
                    padding-top: 25px;
                    padding-bottom: 25px;
                }

    .listing-table th.normal, .listing-table th.x-large, .listing-table th.medium, .listing-table th.small, .listing-table th.large {
        width: 100%;
        max-width: 100%;
    }

    .fixed-table-container .th-inner {
        position: inherit;
        line-height: 35px;
        text-align: left;
        border-left: 1px solid #aaa;
        padding-left: 5px;
        margin-left: -6px;
        font-size: 14px;
        background-color: lightGrey;
        width: 100%;
    }

    .header-background {
        display: none;
    }

    .fixed-table-container-inner {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
        margin-top: -32px;
    }

    table.roundRows td {
        width: 100% !important;
        padding-left: 10px;
    }

        table.roundRows td:first-child, table.roundRows td:last-child {
            border-left-style: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        table.roundRows td:first-child, table.roundRows td:last-child {
            border-right-style: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

    span.base-label {
        width: inherit;
    }

    .form-row {
        padding-left: 0;
    }

        .form-row.oAuth {
            border-top: none;
            padding-top: 0;
            padding-bottom: 10px;
        }

    div.statusNav {
        margin: 10px 15px;
        border-top: 1px solid grey;
    }

    label.control-label {
        margin-left: 5px;
    }

        label.control-label.inline {
            display: block;
            font-weight: bold !important;
            text-align: left;
            margin-left: 5px;
        }

    /*Drop Down Check Box*/
    .dd_chk_select_cust {
        width: 250px;
    }

    span.control-label.inline, label.control-label.inline {
        width: 150px;
    }

    span.searchPropertyDescription {
        height: auto;
        display: block;
        padding: 5px;
    }


    .box-section {
        border: none;
        padding: 0px 5px;
    }

        .box-section:first-child {
            margin-top: 5px;
        }
}

/* DESK TOP CONTENT CUSTOM*/
@media all and (min-width:768px) {

    .commentParagragh {
        line-height: 35px;
    }

    .listingResults {
        min-height: 500px
    }

    .desktopHide {
        display: none !important;
    }

    .container {
        *zoom: 1;
        width: 100% !important;
        /*min-width: 1170px !important;*/
    }

    .site-menu .container {
        max-width: 100%;
    }

    div.mainPanel {
        margin: 20px;
        margin-top: 40px;
    }

    div.pageHeader {
        /*border-bottom: 2px solid #344692;*/
        margin-bottom: 20px;
        padding-top: 20px;
    }

    .main-search {
        /*height: 500px;*/
        position: relative;
        margin-top: 4px;
        margin-bottom: 30px;
    }

        .main-search:before {
            display: block;
            content: "";
            width: 100%;
            height: 2px;
            background: #cb4d50;
            margin-top: -2px;
            margin-bottom: 2px;
        }

        .main-search .form {
            padding: 20px 40px;
            position: absolute;
            left: 50%;
            margin-left: -300px;
            top: 80px;
            background: rgba(211, 208, 208, 0.70);
            width: 600px;
            z-index: 100;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            *zoom: 1;
        }

        .main-search .formSUB {
            padding: 20px 40px;
            position: absolute;
            left: 50%;
            margin-left: -300px;
            top: 280px;
            background: #ccc;
            width: 600px;
            z-index: 100;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            *zoom: 1;
        }

        .main-search .form:before {
            content: '';
            display: block;
        }

        .main-search .form:after {
            content: '';
            display: table;
            clear: both;
        }

        .main-search h2 {
            font-size: 36px;
            font-weight: 300;
            color: #fff;
            text-align: center;
            margin-bottom: 20px;
        }

            .main-search h2:before {
                content: none;
                display: none !important;
                border: 0;
            }

        .main-search select {
            background: rgb(68,138,255);
            color: #fff;
            border: none;
            float: left;
            height: 36px;
            font-size: 18px;
            margin-right: 0;
            width: auto !important;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
        }

        .main-search input {
            width: 370px;
            color: #ccc;
            border-color: #fff;
            height: 36px;
            float: left;
            font-size: 18px;
            margin-right: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
        }

            .main-search input:focus {
                color: #444;
            }

        .main-search button {
            display: block;
            border: none;
            float: left;
            height: 36px;
            line-height: 32px;
            font-size: 18px;
            margin: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
        }

    /*Drop Down Check Box*/
    .dd_chk_select_cust {
        width: 300px;
    }
}

/*Progress*/
div.WarningProgress {
    width: 90%;
    height: 10px;
    background-color: darkorange;
    display: inline-block;
    cursor: help;
}

div.RequiredProgress {
    width: 90%;
    height: 10px;
    background-color: red;
    display: inline-block;
    cursor: help;
}

div.CompleteProgress {
    width: 0%;
    height: 10px;
    background-color: green;
    font-size: 7px;
    cursor: help;
}
/*CRUD*/
div.CRUD {
    display: none;
    position: absolute;
    border: 1px solid #333;
    background-color: #161616;
    border-radius: 2px;
    padding: 5px;
    color: #fff;
    font-size: 10px;
    z-index: 199991;
}

.fa {
    vertical-align: middle
}

    .fa.action {
        padding: 0 2px;
        font-size: 1.5em;
    }

.findHome {
    padding: 20px 0px;
}

li.dropdown {
    line-height: 100%;
    margin-bottom: 0px;
}

select.textBox {
    /*for firefox*/
    -moz-appearance: none;
    /*for chrome*/
    -webkit-appearance: none;
}

    /*for IE10*/
    select.textBox::-ms-expand {
        display: none;
    }

.slIcon {
    background-color: white;
    border: 1px solid black;
    padding: 3px 5px;
    border-radius: 2px;
}

.notSettled {
    background-color: moccasin;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABZJREFUeNpi2r9//38gYGAEESAAEGAAasgJOgzOKCoAAAAASUVORK5CYII=);
    border: 1px solid black;
    padding: 3px 5px;
    border-radius: 2px;
}

.table-striped > tbody > tr:nth-of-type(2n+1) {
    background-color: rgba(227, 227, 227, 1);
}

.table-striped3 > tbody > tr, .table-striped2 > tbody > tr {
    background-color: white;
}

    .table-striped3 > tbody > tr:nth-child(3n+1), .table-striped3 > tbody > tr:nth-child(3n+2), .table-striped2 > tbody > tr:nth-child(4n+1), .table-striped2 > tbody > tr:nth-child(4n+2) {
        background-color: rgba(227, 227, 227, 1);
    }

.table-bordered > tbody > tr > td {
    border: none !important;
}

tr.overFlowRow > td {
    border: none !important;
}

#ui-datepicker-div {
    display: none;
}
/* content page init hide thead datepicker UI*/

table.dataTable > tbody > tr > td {
    vertical-align: middle
}

div.dataTables_info, div.dataTables_length {
    padding: 5px 10px;
}

.agent {
    padding: 20px;
    border: 1px solid lightgrey;
    border-top-color: rgb(68,138,255);
    border-top-width: 3px !important;
    margin-top: -50px;
    background-color: white;
    opacity: 1;
}

.borderPanel {
    padding: 20px;
    border: 1px solid lightgrey;
    border-top-color: rgb(68,138,255);
    border-top-width: 3px !important;
    background-color: white;
    opacity: 1;
}

    .borderPanel.normal {
        border-top-color: none;
        border-top-width: thin;
    }

.darkPanel {
    padding: 20px;
    border: 1px solid #666;
    border-top-color: rgb(68,138,255);
    border-top-width: 3px !important;
    background-color: #eee;
    opacity: 1;
}

.borderPanel > h1, .borderPanel > h2, .borderPanel > h3, .borderPanel > h4 {
    margin-top: 10px
}
/*Radio Buttons*/

label.btn, label.btn:active {
    background-color: none !important;
}
/*label.btn span {
  font-size: 1.5em ;
}*/

label input[type="radio"] ~ i.fa.fa-circle-o {
    color: #c8c8c8;
    display: inline;
}

label input[type="radio"] ~ i.fa.fa-dot-circle-o {
    display: none;
}

label input[type="radio"]:checked ~ i.fa.fa-circle-o {
    display: none;
}

label input[type="radio"]:checked ~ i.fa.fa-dot-circle-o {
    color: #7AA3CC;
    display: inline;
}

label:hover input[type="radio"] ~ i.fa {
    color: #7AA3CC;
}

label input[type="checkbox"] ~ i.fa.fa-square-o {
    color: #c8c8c8;
    display: inline;
}

label input[type="checkbox"] ~ i.fa.fa-check-square-o {
    display: none;
}

label input[type="checkbox"]:checked ~ i.fa.fa-square-o {
    display: none;
}

label input[type="checkbox"]:checked ~ i.fa.fa-check-square-o {
    color: #7AA3CC;
    display: inline;
}

label:hover input[type="checkbox"] ~ i.fa {
    color: #7AA3CC;
}

div[data-toggle="buttons"] label.active {
    /*color: #7AA3CC;*/
    background: none !important;
}

div[data-toggle="buttons"] label {
    display: inline-block;
    padding: 6px 12px 6px 0px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    /*line-height: 2em;*/
    text-align: left;
    white-space: nowrap;
    vertical-align: top;
    cursor: pointer;
    background-color: none;
    border: 0px solid #c8c8c8;
    border-radius: 3px;
    color: #c8c8c8;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

    div[data-toggle="buttons"] label:hover {
        color: #7AA3CC;
    }

    div[data-toggle="buttons"] label:active, div[data-toggle="buttons"] label.active {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
/*End Radio Buttons*/

/*Header*/
#header {
    color: #fff;
    cursor: default;
    height: 3.25em;
    left: 0;
    line-height: 3.25em;
    position: absolute;
    text-align: right;
    top: 2em;
    width: 100%;
    z-index: 10001;
}

    #header .inner {
        margin: 0 auto;
        position: relative;
    }

    #header .logo {
        color: #ffffff;
        display: inline-block;
        font-weight: 400;
        height: inherit;
        left: 0;
        line-height: inherit;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        font-size: 1em;
    }

        #header .logo strong {
            color: #ffffff;
            font-weight: 600;
        }

    #header .dropdown-menu li {
        line-height: .25em;
    }


.dropdown-menu li > a.product, .dropdown-menu li > a:hover .product {
    color: #438fbd !important;
    font-weight: bold;
    width: 100%
}

#header .dropdown-menu li > a, #header .dropdown-menu li > a:hover {
    color: black;
    width: 100%;
}

#header a:not(.btn) {
    -moz-transition: color 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out;
    -ms-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
    display: inline-block;
    padding: 0 0.75em;
    color: inherit;
    text-decoration: none;
    font-size: 1em;
    width: 100%
}

#header.btn {
    color: black;
}

#header a:hover:not(.btn) {
    color: #ffffff;
}

#header a:last-child {
    padding-right: 0;
}

#header a.navPanelToggle {
    display: none;
    text-decoration: none;
    height: 4em;
    width: 4em;
    z-index: 10003;
}

    #header a.navPanelToggle .fa {
        font-size: 1.25em;
    }

@media screen and (max-width: 980px) {

    #header a.navPanelToggle {
        display: inline-block;
    }
}

@media screen and (max-width: 736px) {

    #header a {
        padding: 0 0.5em;
    }
}

@media screen and (max-width: 980px) {

    #header {
        top: 1em;
    }
}

@media screen and (max-width: 736px) {

    #header {
        top: .5em;
    }
}

@media screen and (max-width: 480px) {

    #header {
        font-size: .9em;
    }
}
/*End Header*/

/*mdl slider - customization*/
.mdl-slider {
    width: inherit;
}

.divParent {
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    font-size: 1.6em;
    display: table;
}

.loginPanel {
    /* border: 1px solid black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
       */
    padding: 10px;
    overflow: auto;
    width: 25%;
    position: absolute;
    top: -300px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 600px;
    padding: 10px;
}

fielddata.fielddata, fielddata.FIELDDATA {
    background-color: yellow;
    text-decoration: underline;
    padding: 0px 10px;
    cursor: not-allowed;
}

    fielddata.fielddata.bool, fielddata.fielddata.BOOL {
        border: 1px solid black;
        padding: 2px;
    }

/* Flex */

.flex {
    display: -ms-flexbox;
    -ms-flex-wrap: wrap;
    -ms-flex-pack: justify;
    -moz-justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
}

    .flex.flex-2 {
        -moz-justify-content: space-between;
        -webkit-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
    }

        .flex.flex-2 article {
            width: 50%;
        }

@media screen and (max-width: 980px) {

    .flex.flex-2 article {
        width: 100%;
        margin-bottom: 3em;
    }

        .flex.flex-2 article:last-child {
            margin-bottom: 0;
        }
}

@media screen and (max-width: 480px) {

    .flex.flex-2 br {
        display: none;
    }
}

/* Section/Article */

section.special, article.special {
    text-align: center;
}

    section.special article, article.special article {
        text-align: left;
    }

section.wrapper, article.wrapper {
    /*    padding: 6em 0;*/
}

    section.wrapper header, article.wrapper header {
        margin-bottom: 4em;
    }

        section.wrapper header h2, article.wrapper header h2 {
            font-size: 2.75em;
            margin: 0 0 .5em 0;
        }

        section.wrapper header p, article.wrapper header p {
            font-size: 1em;
        }

    section.wrapper article header, article.wrapper article header {
        margin: 0;
    }

@media screen and (max-width: 980px) {

    section.wrapper, article.wrapper {
        padding: 4em 0;
    }
}

@media screen and (max-width: 736px) {

    section.wrapper, article.wrapper {
        padding: 3em 0;
    }

        section.wrapper header, article.wrapper header {
            margin-bottom: 2em;
        }

            section.wrapper header h2, article.wrapper header h2 {
                font-size: 2em;
            }

            section.wrapper header p, article.wrapper header p {
                font-size: .9em;
            }
}

@media screen and (max-width: 480px) {

    section.wrapper article, article.wrapper article {
        text-align: center;
    }
}

.underline {
    text-decoration: underline;
}

/*Drop Down with Items*/
.dropdownMS {
    position: absolute;
    display: inline-block;
    padding-left: 3px;
    /*top:50%;
  transform: translateY(-50%);*/
}

    .dropdownMS dl dt {
        font-size: 100%;
        font-weight: normal;
    }

    .dropdownMS dd,
    .dropdownMS dt {
        margin: 0px;
        padding: 0px;
        z-index: 20;
    }

    .dropdownMS ul {
        margin: -1px 0 0 0;
    }

    .dropdownMS dd {
        position: relative;
    }

    .dropdownMS a,
    .dropdownMS a:visited {
        /*color: #fff;*/
        text-decoration: none;
        outline: none;
        /*font-size: 12px;*/
    }

    .dropdownMS dt a {
        /*background-color: #4F6877;*/
        display: block;
        padding: 5px;
        min-height: 25px;
        /*line-height: 24px;*/
        overflow: hidden;
        border: 1px solid #aaa;
        width: 272px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-shadow: inset 0 0 3px #cccccc;
        -moz-box-shadow: inset 0 0 3px #cccccc;
        -webkit-box-shadow: inset 0 0 3px #cccccc;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }

        .dropdownMS dt a span,
        .multiSel span {
            cursor: pointer;
            display: inline-block;
            /*padding: 0 3px 2px 0;*/
        }

    .dropdownMS dd ul {
        background-color: white;
        border: 1px solid #cccccc;
        color: black;
        display: none;
        left: 0px;
        padding: 2px 15px 2px 5px;
        position: absolute;
        top: 2px;
        width: 280px;
        list-style: none;
        max-height: 100px;
        overflow: auto;
    }

    .dropdownMS span.value {
        display: none;
    }

    .dropdownMS dd ul li a {
        padding: 5px;
    }

    .dropdownMS dd ul li label .dropdownMS dd ul li a {
        display: inline;
    }

    .dropdownMS dd ul li a:hover {
        background-color: #fff;
    }

/*End DropDown with Items*/

/*div Center*/
.verticalCenterOuter {
    display: table;
    position: absolute;
    height: 100%;
    width: 100%;
}

.verticalCenterMiddle {
    display: table-cell;
    vertical-align: middle;
}

.verticalCenterContent {
    margin-left: auto;
    margin-right: auto;
    width: 800px; /*whatever width you want*/
}
/*End div center*/

/*nx-datatable*/
.datatable-body-cell-label {
    overflow: hidden
}

datatable-body-cell.number {
    text-align: right;
}

datatable-header {
    background-color: cornsilk
}

datatable-row-wrapper {
    border-bottom: 1px solid black;
}

button.angular-editor-button {
    color: grey;
}

.editor {
    width: 100%;
    height: 440px;
    margin: 10px;
}

.editorDrawer {
    max-width: 49%;
    overflow-x: hidden;
}

#thumbnail:hover + #titleSelect {
    display: block;
}

#titleSelect {
    display: none;
}

ngx-material-timepicker-toggle > button {
    display: inherit !important;
}

div.datatable-row-detail {
    overflow-y: visible !important;
    border-bottom: 1px solid grey;
}

div.ngxDataTableDetail {
    padding-left: 35px;
    width: Calc(100% - 35px)
}

div.ngxDataTableFooter {
    display: block;
    padding: 5px 10px
}

.ngx-datatable .datatable-footer .datatable-pager {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 60% !important;
    flex: 1 1 60% !important;
    text-align: right;
}

.datatable-row-even {
    background-color: gainsboro;
}

.datatable-body-cell {
    white-space: initial !important;
    text-overflow: initial;
}
/*datatable-body-cell > div > mat-form-field{
    padding-top:10px;
}*/

img.person {
    border: 1px inset grey;
    height: 200px;
    width: 200px;
    border-radius: 5px;
}

img.listing {
    width: 150px;
    height: 150px;
    max-width: 150px;
    max-height: 150px;
}

.fancy-checkbox input[type="checkbox"],
.fancy-checkbox .checked {
    display: none;
}

    .fancy-checkbox input[type="checkbox"]:checked ~ .checked {
        display: inline-block;
    }

    .fancy-checkbox input[type="checkbox"]:checked ~ .unchecked {
        display: none;
    }

div.glossary > h2 {
    display: inline;
}

div.glossary > h4 {
    margin-top: 10px;
}

mapMarker:hover {
    background-color: blue;
    cursor: pointer;
    border: 1px solid blue;
}

mapMarker {
    width: 36px;
    height: 36px
}

figure.unsolicited {
    background-color: lightpink;
}

figure > img.profile {
    height: 100px;
    width: 100px;
}

/* Accordion with 3 panels, stretching from top to bottom */
.mat-accordion.vertical {
    display: block;
    transform-origin: top left;
    transform: rotate(-90deg) translateX(-100vh); /* rotate and position it; translateX value corresponds to panel height */
    width: 100vh; /* this will be the height of the accordion (inversed due to rotation) */
}

.mat-expansion-panel.vertical5 {
    max-height: calc(100vw / 5); /* this will be the width of the panel (inversed due to rotation), 3 is panel amount */
}

.panel-content.vertical5 {
    background-color: lightblue;
    transform-origin: top left;
    transform: rotate(90deg); /* rotate back */
    margin-left: 100%; /* position it */
    height: 100vw; /* real height of the content */
    width: calc(100vw / 5 - 50px); /* real width of the content, 3 is panel amount */
}

.mat-expansion-panel.vertical4 {
    max-height: calc(100vw / 4); /* this will be the width of the panel (inversed due to rotation), 3 is panel amount */
}

.panel-content.vertical4 {
    background-color: lightblue;
    transform-origin: top left;
    transform: rotate(90deg); /* rotate back */
    margin-left: 100%; /* position it */
    height: 100vw; /* real height of the content */
    width: calc(100vw / 4 - 50px); /* real width of the content, 3 is panel amount */
}

.right-aligned-header > .mat-content {
    justify-content: space-between;
}

mat-expansion-panel.header.leftAlign > .mat-content > mat-panel-title, mat-expansion-panel.header.leftAlign > .mat-content > mat-panel-description {
    flex: 0 0 auto;
}

#hideIcon > div > mat-step-header.mat-step-header > div.mat-step-icon {
    display: none;
}

.grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

    /* (Optional) Apply a "closed-hand" cursor during drag operation. */
    .grabbable:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }

div.mat-step-label-selected {
    border-bottom: 2px solid black !important;
}

.dndDropzoneArea {
    border: 2px dotted green;
    padding: 10px;
}

.offerExpiring {
    background-color: brown !important
}

.rateLockExpiring {
    background-color: coral !important
}

.buyerVisit {
    background-color: forestgreen !important
}

.workOrderVisit {
    background-color: black !important
}

ol > li {
    padding-bottom: 10px;
}

.hideDetailsOnCollapse .mat-expansion-panel-header-title,
.hideDetailsOnCollapse .mat-expansion-panel-header-description {
    flex-basis: 0;
}

.carousel-caption > h4 {
    color : black
}

/* SVG Guage */
.svgGuage.flex-wrapper {
    display: flex;
    flex-flow: row nowrap;
}

.svgGuage.single-chart {
    /*width: 33%; pass the size from Component*/
    justify-content: space-around;
    display:inline-block;
}

.svgGuage.circular-chart {
    display: block;
    /*margin: 10px auto;*/
    max-width: 80%;
    max-height: 250px;
}

.svgGuage.circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width:3.8;
}

.svgGuage.circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}

.svgGuage.circular-chart.grey .svgGuage.circle {
    stroke: lightgrey;
}

.svgGuage.circular-chart.warning .svgGuage.circle {
    stroke: tomato;
}

.svgGuage.circular-chart.warning .svgGuage.circle {
    stroke: #ff9f00;
}

.svgGuage.circular-chart.green .svgGuage.circle {
    stroke: #4CC790;
}

.svgGuage.circular-chart.complete .svgGuage.circle {
    stroke: #3c9ee5;
}

.svgGuage.percentage {
    fill: #666;
    font-family: sans-serif;
    font-size: 0.5em;
    text-anchor: middle;
}

div#divSignIn, div#divSignUp > mat-card, mat-card {
    padding: 10px!important
}

    div#divSignIn > div > mat-form-field, div#divSignIn > div > div > mat-form-field {
        width: 100%;
    }

.mat-mdc-form-field .mat-mdc-form-field-hint-wrapper {
    position: static !important;
}